import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Instructions from './Instructions';
import { Switch, Route } from 'wouter';

ReactDOM.render(
  <React.StrictMode>
    <Switch>
      <Route path="/instructions">
        <Instructions />
      </Route>
      <Route>
        <App />
      </Route>
    </Switch>
  </React.StrictMode>,
  document.getElementById('root')
);
